import { formatDate } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccRoles, AppRoles } from '@apx-ui/apx-config';
import { TIME_ZONES } from '@apx-ui/apx-core';
import {
  SuspendReason,
  UserStateService,
} from '@apx-ui/apx-web-api-v1';
import moment from 'moment/moment';
import { Subscription } from 'rxjs';

interface DialogData {
  reasons: SuspendReason[];
  data: {
    CustomPauseReason: string;
    ReasonId: string;
    PauseReason: string;
    PauseStartDate: string;
    PauseEndDate: string;
    PauseType: string;
  }
}

function validator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const formModel = control.value as { startDate: string; endDate: string; endAtEnable: boolean; };

    return valueValidator(formModel);
  };
}

function valueValidator(model: { startDate: string; endDate: string; endAtEnable: boolean; }): ValidationErrors | null {
  const startAtMoment = moment(model.startDate);
  const endAtMoment = moment(model.endDate);

  const datesValid = !model.endAtEnable ||
    model.endAtEnable
    && model.startDate
    && endAtMoment.diff(startAtMoment, 'days') >= 0;

  return datesValid ? null : {
    invalidDate: true,
  };
}

@Component({
  selector: 'apx-ui-shared-suspend-dialog',
  templateUrl: './suspend-dialog.component.html',
  styleUrls: ['./suspend-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuspendDialogComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  reasons: SuspendReason[];
  minDate = new Date();
  startDate: string;
  endDate: string;
  isAdmin: boolean;
  minBackDate: Date;

  private formChangesSubscription?: Subscription;
  private subscription = new Subscription();
  private timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone || TIME_ZONES.CST;

  public constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly dialogRef: MatDialogRef<SuspendDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    private readonly userStateService: UserStateService,
  ) {
  }

  get getReason(): AbstractControl | null {
    return this.form.get('reason');
  }

  get getStartDate(): AbstractControl | null {
    return this.form.get('startDate');
  }

  get getEndDate(): AbstractControl | null {
    return this.form.get('endDate');
  }

  get endAtEnable(): AbstractControl | null {
    return this.form.get('endAtEnable');
  }

  onStartDateChange(): void {
    if(!this.getStartDate.value){
      this.form.get('startDate').setValue(new Date());
    }
    const day = moment(this.getStartDate.value).hours(0).minutes(0).seconds(0).milliseconds(0);
    this.startDate = `${day.format('YYYY-MM-DDTHH:mm:ss.SSS')  }Z`;
  }

  onEndDateChange(): void {
    if(!this.getEndDate.value){
      this.form.get('endDate').setValue(new Date());
    }
    const day = moment(this.getEndDate.value);
    this.endDate = `${day.format('YYYY-MM-DDTHH:mm:ss.SSS')  }Z`;
  }

  startDateSuspendFilter = (d: Date | null): boolean => {
    const day = d?.setHours(0, 0, 0, 0);
    const startDate = new Date(this.getStartDate?.value).setHours(0, 0, 0, 0);
    const minDate = this.minBackDate.setHours(0, 0, 0, 0);

    return day === startDate || minDate <= day;
  };

  ngOnInit(): void {
    this.reasons = this.dialogData.reasons;
    this.subscription.add(
      this.userStateService.getAllRoles$()
        .subscribe(roles => {
          this.isAdmin = roles
            .some(role =>
              ([
                AppRoles.SuperAdmin,
                AppRoles.Admin,
                AccRoles.SuperVisor,
              ] as string[]).includes(role),
            );

          const date = new Date(formatDate(new Date(), 'M/d/yyyy', 'en-US', this.timezone));

          this.minBackDate = this.isAdmin
            ? new Date(date.getFullYear(), date.getMonth(), date.getDate() - 120)
            : new Date(date.getFullYear(), date.getMonth(), date.getDate() - 45);
        }),
    );
    this.initForm();
    this.onStartDateChange();
  }

  ngOnDestroy(): void {
    this.formChangesSubscription?.unsubscribe();
    this.subscription.unsubscribe();
  }

  onSubmit(): void {
    // eslint-disable-next-line max-len
    const defaultEndDate = this.dialogData.data.PauseEndDate ? `${moment(this.dialogData.data.PauseEndDate).format('YYYY-MM-DDTHH:mm:ss.SSS') }Z` : null;
    this.form.get('startDate').setValue(this.startDate);
    // eslint-disable-next-line no-unused-expressions
    this.endDate ? this.form.get('endDate').setValue(this.endDate) : this.form.get('endDate').setValue(defaultEndDate);

    const payload = {
      ...this.dialogData,
      ReasonId: this.getReason.value.reason,
      CustomPauseReason: this.getReason.value.comment || null,
      UpdatedPauseStartDate: this.getStartDate.value,
      UpdatedPauseEndDate: this.getEndDate.value,
    };

    this.dialogRef.close(payload);

  }

  private initForm(): void {
    const endAt = this.dialogData.data.PauseEndDate ?? null;

    this.form = this.fb.group({
      startDate: [this.dialogData.data.PauseStartDate ?? new Date()],
      endAtEnable: [!!endAt],
      endDate: [{ value: this.dialogData.data.PauseEndDate ?? null, disabled: !endAt }],
      reason: [{ reason: this.dialogData.data.ReasonId }, Validators.required],
    }, { validator: validator() });

    this.formChangesSubscription = this.endAtEnable.valueChanges.subscribe(enable => {
      if (enable) {
        this.getEndDate?.enable();
        this.getEndDate?.setValue(new Date());
      } else {
        this.getEndDate?.setValue(null);
        this.getEndDate?.disable();
      }
    });

  }
}
